import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { AuthMember } from "../../helpers/auth";
import { getMember } from "../../api/member-api-calls";
import { SideMenu } from "../../components/navBar/socialHub/sideMenu";
import { NavBar } from "../../layouts/navBar";
import { PersonalDetails } from "../../layouts/forms/onboarding/personal";
import { ButtonLoader } from "../../components/btnLoader";
import { updateMember } from "../../api/member-api-calls";
import { ErrorMessage } from "../../components/forms/error";
import { SuccessMessage } from "../../components/forms/success";
import {
    validateEmail,
    validatePhoneNumber,
    validateUrl,
} from "../../helpers/validation";

export const MyAccountProfilePage = () => {
    const [member, setMember] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        AuthMember(jwt_decode);
        getMember(setMember);
    }, []); //eslint-disable-line

    const disabled =
        member.first_name === "" ||
        member.last_name === "" ||
        member.email === "" ||
        member.phone_number === "";

    const submitData = () => {
        try {
            setBtnLoading(true);
            validateEmail(member.email);
            validatePhoneNumber(member.phone_number);

            if (member.company_email !== "")
                validateEmail(member.company_email);
            if (member.company_phone !== "")
                validatePhoneNumber(member.company_phone);
            if (member.website_link !== "") validateUrl(member.website_link);

            setError(false);

            updateMember(member, updateMember, setBtnLoading, setError).then(() => {
                setSuccess("Profile updated successfully");
                setBtnLoading(false);
            });
        } catch (error) {
            setBtnLoading(false);
            setError(error.message);
        }
    };

    console.log("member", member);

    return (
        <>
            <NavBar />
            <div className="pt-[3.8rem] md:pt-[6.8rem] bg-slate-100 h-screen">
                <div className="flex flex-col md:flex-row md:space-x-8 px-6 lg:px-16">
                    <div className="md:w-3/12 py-10">
                        <SideMenu />
                    </div>
                    <div className="w-full md:w-8/12 lg:w-5/12 bg-white md:my-10 shadow-lg rounded-lg">
                        <div className="space-y-8 md:overflow-y-auto md:max-h-[88vh]">
                            <div className="space-y-6 p-5">
                                <h3 className="font-semibold text-2xl my-5">
                                    My Profile
                                </h3>
                                {"first_name" in member && (
                                    <>
                                        {success && (
                                            <SuccessMessage
                                                success={success}
                                                setSuccess={setSuccess}
                                            />
                                        )}
                                        {error && (
                                            <ErrorMessage
                                                error={error}
                                                setError={setError}
                                            />
                                        )}

                                        <PersonalDetails
                                            data={member}
                                            updateData={setMember}
                                        />
                                        <button
                                            type="button"
                                            disabled={disabled}
                                            className={`w-full tracking-widest ${
                                                disabled || btnLoading
                                                    ? "bg-gray-200"
                                                    : "bg-[#329E49] hover:bg-[#3ab554]"
                                            }  text-white flex justify-center font-medium rounded-lg text-sm px-5 py-3 mt-5 transition duration-700 ease-in-out`}
                                            onClick={() => {
                                                submitData();
                                            }}
                                        >
                                            {btnLoading ? (
                                                <ButtonLoader />
                                            ) : (
                                                "UPDATE PROFILE"
                                            )}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
