import React, { useState } from "react";
import { Page } from "../../layouts/page";

export const TermsAndConditionsPage = () => {
    const [data, updateData] = useState([]);
    return (
        <Page
            title="Privacy Policy"
            description=" This Privacy Policy explains how The Clean Cooking Association of Kenya (CCAK) collects, uses, and protects your personal information. By using our services and website, you agree to the terms outlined in this policy."
            page="privacy-policy"
            data={data}
            updateData={updateData}
        >
            <div className="pt-[3.8rem] lg:pt-[6.6rem]">
                <div className="p-6 lg:py-24 lg:px-32">
                    <h1 className="text-4xl font-bold my-3">
                        CCAK Membership & Website Terms{" "}
                    </h1>
                    <p>
                        Upon mutual acceptance of the Terms and Conditions
                        herein, The Clean Cooking Association of Kenya (CCAK)
                        enters into a Membership Agreement with you, as the
                        Member, entitled to membership benefits.{" "}
                    </p>
                    <div className="my-5">
                        <h3 className="text-2xl font-bold my-3">Definitions</h3>
                        <ul className="font-manjari space-y-4 list-disc px-10">
                            <li>
                                Member: The company or organization that has
                                joined The Clean Cooking Association of Kenya
                                (CCAK) in order to participate in the Forums and
                                other activities through execution of the
                                Membership Agreement.
                            </li>
                            <li>
                                Membership Agreement: The Agreement executed by
                                The Clean Cooking Association of Kenya and the
                                Member through which the Member becomes a member
                                of the association.{" "}
                            </li>
                            <li>
                                Forum(s) and Work Group(s): Working entities set
                                up by The Clean Cooking Association of Kenya for
                                the benefit of its members.{" "}
                            </li>
                            <li>
                                Information: Any specifications, drawings,
                                sketches, models, samples, data, computer
                                programs or documentation or other technical or
                                business information in written, graphic or
                                other tangible or electronic form furnished or
                                disclosed to The Clean Cooking Association of
                                Kenya or any other party in the course of
                                Membership Meetings, Forums’ or Work Group
                                activities and/or as a result of the Member’s
                                use of such Materials.{" "}
                            </li>
                            <li>
                                Contribution(s): Information that is provided by
                                the Member in the course of discussions leading
                                to Material to be published by The Clean Cooking
                                Association of Kenya.{" "}
                            </li>
                            <li>
                                Material(s): Standards, specifications, white
                                papers, guides, reports, snapshots, case
                                studies, literature, and other documentation
                                published by The Clean Cooking Association of
                                Kenya and partners.
                            </li>
                        </ul>
                    </div>
                    <div className="my-5">
                        <h3 className="text-2xl font-bold my-3">
                            The Clean Cooking Association of Kenya T&Cs.
                        </h3>
                        <p>
                            Member participation in any CCAK Membership
                            Meetings, Forums’ or Work Group activities shall be
                            governed by The CCAK T&Cs, available here.{" "}
                        </p>
                    </div>
                    <div className="my-5">
                        <h3 className="text-2xl font-bold my-3">
                            Publications
                        </h3>
                        <ul className="font-manjari space-y-4 list-disc px-10">
                            <li>
                                To enable open, voluntary, neutral discussions
                                and contributions towards Material to be
                                published by The Clean Cooking Association of
                                Kenya (CCAK), the Member agrees to the following
                                terms of confidentiality with respect to
                                Information received through participation:
                            </li>
                            <li>
                                By checking the consent to use info and pictures
                                field in the meeting attendance registration
                                forms, a member agrees that photos of him taken
                                in CCAK meetings can be used in after event
                                publications e.g. Articles, reports and social
                                media posts.
                            </li>
                            <li>
                                In case of a decline to check the consent field,
                                the Association won’t use the member’s
                                photos/information.
                            </li>
                        </ul>
                    </div>
                    <div className="my-5">
                        <h3 className="text-2xl font-bold my-3">
                            Member's Contacts
                        </h3>
                        <ul className="font-manjari space-y-4 list-disc px-10">
                            <li>
                                The databases with the contacts of the
                                association’s members are a property of the
                                Clean Cooking Association of Kenya (CCAK) and
                                shall not be shared with a third party unless
                                there is consent from a given member.
                            </li>
                        </ul>
                    </div>
                    <div className="my-5">
                        <h3 className="text-2xl font-bold my-3">
                            Membership subscription Renewal
                        </h3>
                        <ul className="font-manjari space-y-4 list-disc px-10">
                            <li>
                                The Membership Agreement shall be renewed upon
                                each annual payment based on the category in
                                which a member is. This is subject to making
                                updates in the CCAK membership database to
                                indicate a member’s payment.
                            </li>
                        </ul>
                    </div>
                    <div className="my-5">
                        <h3 className="text-2xl font-bold my-3">
                            Membership termination
                        </h3>
                        <ul className="font-manjari space-y-4 list-disc px-10">
                            <li>
                                The Membership Agreement may be terminated: By
                                either party giving to the other sixty (30)
                                days’ notice in writing.
                            </li>
                            <li>
                                For the avoidance of doubt, termination of the
                                Membership Agreement for any reason shall not
                                entitle the Member to any refund of the annual
                                fee paid.
                            </li>
                        </ul>
                    </div>
                    <div className="my-5">
                        <h3 className="text-2xl font-bold my-3">
                            CCAK Website interaction
                        </h3>
                        <ul className="font-manjari space-y-4 list-disc px-10">
                            <li>
                                By interacting with the CCAK website, a member
                                shall agree to the following conditions:
                            </li>
                            <li>
                                Membership/donations payment: Payments shall be
                                made only through the specified forms of payment
                                on the website and not any other. CCAK shall not
                                be liable for any payments made using the
                                unspecified forms of payment.
                            </li>
                            <li>
                                Language: CCAK shall remove/discontinue members
                                found/reported to have used abusive language on
                                the website’s social platform or any other
                                social media platforms.
                            </li>
                            <li>
                                The content of the pages of this website is for
                                your general information and use only. It is
                                subject to change without notice.
                            </li>
                            <li>
                                This website uses cookies to monitor browsing
                                preferences. If you do allow cookies to be used,
                                some personal information may be stored by us
                                for use by third parties.
                            </li>
                            <li>
                                Your use of information or materials posted by
                                other members on this website is entirely at
                                your own risk, for which CCAK shall not be
                                liable. It shall be your own responsibility to
                                ensure that any products, services or
                                information available through this website meet
                                your specific requirements.
                            </li>
                            <li>
                                This website contains material which is owned by
                                CCAK and its partners. This material includes,
                                but is not limited to, the design, layout, look,
                                appearance and graphics. Reproduction is
                                prohibited other than in accordance with the
                                copyright notice.
                            </li>
                            <li>
                                From time to time the CCAK website may also
                                include links to other websites. These links are
                                provided for your convenience to provide further
                                information. We have no responsibility for the
                                content of the linked website(s).
                            </li>
                            <li>
                                Your use of the CCAK website and any dispute
                                arising out of such use of the website is
                                subject to the laws of the Republic of Kenya.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Page>
    );
};
